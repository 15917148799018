<template>
    <div :class="{ expanded: !collapsed, collapsed: collapsed }" class="sidebar-container fixed z-50 p-2 bg-company-blue text-white text-sm h-full shadow ">
        <div class="flex mb-4 pb-3 p-2 justify-between items-center border-b border-gray-500">
            <p v-if="!collapsed">Menu</p>
            <div @click="toggleSidebar" class="rounded-lg cursor-pointer hover:bg-gray-200 hover:bg-opacity-20 transition w-3">
                <i v-if="collapsed" class="fas fa-chevron-right" />
                <i v-else class="fas fa-chevron-left" />
            </div>
        </div>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-bank fa-fw text-center text-lg" />
            </template>
            <template #label>
                Bank
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/conciliation">
                        Avstämning
                    </SubmenuItem>
                    <SubmenuItem to="/bank_files">
                        Bankfiler
                    </SubmenuItem>
                    <SubmenuItem to="/account_files">
                        Kontoutdrag
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-file-invoice fa-fw text-center text-lg" />
            </template>
            <template #label>
                Fakturor
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/att_atgarda">
                        Att åtgärda
                    </SubmenuItem>
                    <SubmenuItem to="/avstamning" v-if="!isGlobalClientSelected">
                        Avstämning
                    </SubmenuItem>
                    <SubmenuItem to="/customerAccountsLedger">
                        Kundreskontra
                    </SubmenuItem>
                    <SubmenuItem to="/invoices">
                        Lista
                    </SubmenuItem>
                    <SubmenuItem to="/inpayments">
                        Inbetalningar
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-file-invoice fa-fw text-center text-lg" />
            </template>
            <template #label>
                Fakturaköp
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/clientFactoringList">
                        Klientlista
                    </SubmenuItem>
                    <SubmenuItem to="/factoringInvoiceOverview">
                        Fakturor
                    </SubmenuItem>
                    <SubmenuItem to="/salesLedger">
                        Reskontra
                    </SubmenuItem>
                    <SubmenuItem to="/bankAccountOverview">
                        Bankkonto
                    </SubmenuItem>
                    <SubmenuItem to="/redFlagCustomers">
                        Spärrlista
                    </SubmenuItem>
                    <SubmenuItem to="/factoringSettings">
                        Inställningar
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-money-bill fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Leverantörsfaktura
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/supplierInvoiceListOld">
                        Leverantörsfaktura gammal
                    </SubmenuItem>
                    <SubmenuItem to="/supplierInvoiceList">
                        Leverantörsfaktura
                    </SubmenuItem>
                    <SubmenuItem to="/highValueSupplierInvoice">
                        Högt värde leverantörsfakturor
                    </SubmenuItem>
                    <SubmenuItem to="/suppliers">
                        Leverantörer
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>
        <MenuItem :to="cilentRoute">
            <template #icon>
                <i class="far fa-user-crown fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Klienter
            </template>
        </MenuItem>
        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-book-open fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Bokföring
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/lopande">
                        Löpande
                    </SubmenuItem>
                    <SubmenuItem to="/accountPlans">
                        Kontoplan
                    </SubmenuItem>
                    <SubmenuItem to="/account_categories">
                        Kategorier
                    </SubmenuItem>
                    <SubmenuItem to="/closingBook">
                        Årsredovisning
                    </SubmenuItem>
                    <SubmenuItem to="/annualReport">
                        Årsredovisning NY
                    </SubmenuItem>

                    <SubmenuItem to="/yearEndDisposition">
                        Bokslutsdisposition
                    </SubmenuItem>
                    <SubmenuItem to="/shopify">
                        Shopify
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-shield-check fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                KYC
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/onboardingList" v-if="!isGlobalClientSelected">
                        Onboarding
                    </SubmenuItem>
                    <SubmenuItem to="/kycByClientList">
                        Client info
                    </SubmenuItem>
                    <SubmenuItem to="/kycByClientNew">
                        Lista
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>

        <MenuItem to="/taxAccount">
            <template #icon>
                <img class="mix-blend-screen" src="@/assets/skatteverket_logo.jpg" width="20" />
            </template>
            <template #label>
                Skattekonto
            </template>
        </MenuItem>

        <MenuItem to="/emailType">
            <template #icon>
                <i class="far fa-at fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Skicka epost
            </template>
        </MenuItem>

        <MenuItem to="/kvitton">
            <template #icon>
                <i class="far fa-map fa-fw rotate-90 text-center text-lg text-white" />
            </template>
            <template #label>
                Kvitton
            </template>
        </MenuItem>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-cake-candles fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Löner
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/vacationDebt">
                        Semesterskuld
                    </SubmenuItem>
                    <SubmenuItem to="/salaryVacation">
                        Semesterberedning
                    </SubmenuItem>
                    <SubmenuItem to="/k10_declaration">
                        K10 Declaration
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>
        <MenuItem to="/documents">
            <template #icon>
                <i class="far fa-file-invoice fa-fw text-center text-lg text-white" style="color: #ffffff;" />
            </template>
            <template #label>
                Dokument
            </template>
        </MenuItem>
        <MenuItem to="/loggar">
            <template #icon>
                <i class="far fa-bug fa-fw text-center text-lg text-white" />
            </template>
            <template #label>
                Loggar
            </template>
        </MenuItem>

        <MenuItemExpandable :collapsed="collapsed">
            <template #icon>
                <i class="far fa-ellipsis fa-fw text-center text-lg" />
            </template>
            <template #label>
                Övrigt
            </template>
            <template #content>
                <Submenu>
                    <SubmenuItem to="/chatconversations">
                        Chatbot
                    </SubmenuItem>
                    <SubmenuItem to="/faq">
                        Faq
                    </SubmenuItem>
                </Submenu>
            </template>
        </MenuItemExpandable>
    </div>
</template>
<script>
export default {
    components: {
        MenuItem: () => import(/* webpackChunkName: "AppLayoutSidebarMenuItem" */ "./components/MenuItem.vue"),
        MenuItemExpandable: () => import(/* webpackChunkName: "AppLayoutSidebarMenuItemExpandable" */ "./components/MenuItemExpandable.vue"),
        Submenu: () => import(/* webpackChunkName: "AppLayoutSidebarSubmenu" */ "./components/Submenu.vue"),
        SubmenuItem: () => import(/* webpackChunkName: "AppLayoutSidebarSubmenuItem" */ "./components/SubmenuItem.vue"),
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },

        cilentRoute() {
            return this.isGlobalClientSelected ? "/clientDetails/" + this.$store.state.topBar.selectedClient.value + "/general" : "/clientList";
        },

        clientRoute() {
            return "/clientFactoringList";
        },

        collapsed() {
            return this.$store.state.sidebarCollapsed;
        },
    },

    methods: {
        toggleSidebar() {
            this.$store.commit("toggleSidebar");
        },
    },
};
</script>
<style scoped>
.expanded {
    width: 207px;
}

p {
    user-select: none;
}

.expanded.sidebar-container {
    overflow-y: auto;
}

.expanded.sidebar-container::-webkit-scrollbar {
    display: none;
}
</style>
